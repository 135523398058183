import React, { memo } from "react";
import { MdOutlinePhone } from "react-icons/md";
import { PhoneNumber } from "types/business.types";

type Props = {
	phoneNumber: PhoneNumber;
};

function PhoneBadge({ phoneNumber }: Props) {
	if (phoneNumber) {
		return (
			<a
				href={`tel:+${phoneNumber.countryCode}${phoneNumber.number}`}
				className="flex items-center justify-center space-x-1 border border-zinc-200 whitespace-nowrap text-sm rounded-lg py-3 px-3 text-[#46474A] font-medium transition-all hover:text-black hover:border-zinc-300 bg-white hover:shadow-sm"
			>
				<MdOutlinePhone className="h-5 w-6" />

				<span>
					+{phoneNumber.countryCode} {phoneNumber.formattedNumber}
				</span>
			</a>
		);
	} else {
		return null;
	}
}

export default memo(PhoneBadge);
